import { put, takeLatest, all } from 'redux-saga/effects';
import _ from "lodash"
import { errorFunction } from "../../utilities/errorFunction"
import { callService } from "../../utilities/callService"
import { queryBuilder } from "../../utilities/queryBuilder"
import AsyncStorage  from "../../utilities/encryption/AsyncStorage";
function* fetchInitialUserData(payload)
{
    try
    {
        let { skip, addOnFilter = {}, sortQuery = {}, value, limit } = payload.payload;
        let query = queryBuilder("_find", "allUsers", "User", {
            addOnFilter, skip, limit, sort: sortQuery
        })
        let newData = [];
        let noOfPages = [];
        let data = [];
        let count;
        const response = yield callService(query);
        data = _.get(response, "data.response.result", [])
        if (data.length)
        {
            count = response.data.response.aggregates._count;
            if (count % 2 == 0)
            {
                for (let i = 1; i <= count / 2; i++)
                {
                    noOfPages.push({ index: i });
                }
            }
            else
            {
                for (let i = 1; i <= count / 2 + 1; i++)
                {
                    noOfPages.push({ index: i });

                }
            }
            data.map(item =>
            {
                let { _id = "", name = "", email = "", location = { name: "" }, role = "", status = "", team = "" ,specialization='', first_login= false} = item;
                let dataJson = {
                    id: _id, username: name, email, team, location: location.name, role, status,specialization, first_login
                }
                newData.push(dataJson);
            })
        }
        yield put({ type: "UPDATE_USER_DATA", data: newData, count: count, noOfPages: noOfPages, addOnFilter, sortQuery, value });
    }
    catch (error)
    {
        console.log("error from fetchInitialUserData", error)
        let errorMessage = errorFunction(error);

        yield put({ type: "USER_REQUEST_FAILED", errorMessage });
    }
}
function* fetchSchedulelUserData(payload)
{
    try
    {

        let query = queryBuilder("_find", "allUsers", "User", { addOnFilter: { role: { $in: ["Accepting MD", "Accepting RN"] } } })
        let newData = [];
        let data = [];
        const response = yield callService(query);
        data = _.get(response, "data.response.result", [])
        if (data.length)
        {
            data.map(item =>
            {
                let { _id = "", name = "" } = item;
                let dataJson = {
                    id: _id, username: name
                }
                newData.push(dataJson);
            })
        }
        yield put({ type: "UPDATE_SCHEDULE_USER_DATA", data: newData });
    }
    catch (error)
    {
        console.log("error from fetchSchedulelUserData", error)
        let errorMessage = errorFunction(error);

        yield put({ type: "USER_REQUEST_FAILED", errorMessage });
    }
}
function* activateDeactivate(payload)
{
    try
    {
        let { id, value } = payload.payload
        let query = {
            id: value === "Inactive" ? "deactivateUser" : "activateUser",
            paramValue: {
                _id: id
            },
            token: AsyncStorage.getItem("token")
        }
        const response = yield callService(query);
        let status = response.data.response.result.result.status;
        yield put({ type: "ACTIVATE_DEACTIVATE", id: id, status });
    }
    catch (error)
    {
        console.log("error from activateDeactivate", error)
    }
}
function* actionWatcher()
{
    yield takeLatest('GET_USER_DATA', fetchInitialUserData)
}
function* adWatcher()
{
    yield takeLatest('INITIATE_ACTIVATE_DEACTIVATE', activateDeactivate)
}
function* scheduleActionWatcher()
{
    yield takeLatest('GET_SCHEDULE_USER_DATA', fetchSchedulelUserData)
}
export default function* rootSaga()
{
    yield all([
        actionWatcher(), scheduleActionWatcher(), adWatcher()
    ]);
}